<template>
    <div id="mint">
        <div class="upLoadTitle">Fill Series Info</div>
        <div class="uploadNotes">Before distributing NFT works, the cover picture replaces NFT display</div>
        <div class="uploadNotesInfo">File types supported: JPG, PNG, SVG <span>Max size: 100 MB<span class="required">*</span></span></div>
        <div class="uploadImg">
            <el-upload :class="{hide:hideUpload2}" ref="my-uploadThree" :action="baseUrl" list-type="picture-card" :file-list="fileList2" :on-change="handleChange2" :before-upload="logoBeforeUpload" :on-remove="handleRemove2" :on-success="logoHandleSuccess2">
                <i class="el-icon-picture"></i>
            </el-upload>
        </div>
        <div class="form_view">
            <div class="form_view_title">Series Name<span>*</span></div>
            <div class="form_view_input"><input type="text" v-model="minNewHistoryQuery.seriesname" placeholder="Moment Name"></div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Description<span>*</span></div>
            <div class="form_view_input"><textarea v-model="minNewHistoryQuery.description" placeholder="Provide a detailed description of your item."></textarea></div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Series Logo image<span>*</span></div>
            <div class="uploadNotesInfo">This image will also be used for navigation. 350 x 350 recommended.</div>
            <div class="uploadImgTwo">
                <el-upload :class="{hide:hideUpload1}" ref="my-uploadTwo" :action="baseUrl" list-type="picture-card" :file-list="fileList1" :on-change="handleChange1" :before-upload="logoBeforeUpload" :on-remove="handleRemove1" :on-success="logoHandleSuccess1">
                </el-upload>
            </div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Series Banner image</div>
            <div class="uploadNotesInfo">This image will appear at the top of your series page. Avoid including too much text in this banner image, as the dimensions</div>
            <div class="uploadNotesInfo">change on different devices. 1400 x 400 recommended.</div>
            <div class="uploadImgThree">
                <el-upload :class="{hide:hideUpload}" ref="my-upload" :action="baseUrl" list-type="picture-card" :file-list="fileList" :on-change="handleChange" :before-upload="logoBeforeUpload" :on-remove="handleRemove" :on-success="logoHandleSuccess">
                </el-upload>
            </div>
        </div>
        <div class="mintHandle_btn">
            <button @click="mintHandle">
                <span>Confirm</span>
            </button>
        </div>
    </div>
</template>
<script>
const wallet = require('../../web3/common/wallet.js');

export default {
    name: "create",
    data() {
        return {
            baseUrlData: '',
            baseUrl: 'https://aapi.historydao.io/history/uploadseriesnftpic',
            // baseUrl: 'https://api.blockhistory.io/history/uploadseriesnftpic',
            // baseUrl: "http://18.222.137.245:9081/history/uploadseriesnftpic",
            file: {},
            fileList: [],
            fileList1: [],
            fileList2: [],
            uploadImg: '',
            uploadImg1: '',
            uploadImg2: '',
            hideUpload: false,
            hideUpload1: false,
            hideUpload2: false,
            minNewHistoryQuery: {
                admin: "",
                seriesid: "",
                seriesname: "",
                serieslogo: "",
                seriesbanner: "",
                description: "",
                blindboxcover: "",
                network: '',
            }
        }
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },

    },
    methods: {
        logoHandleSuccess(response) {
            this.uploadImg = response.result.awsUrl;
            this.minNewHistoryQuery.serieslogo = response.result.hash;
        },
        handleChange(file, fileList) {
            fileList.length >= 1 ? this.hideUpload = true : this.hideUpload = false;
        },
        handleRemove(file, fileList) {
            fileList.length >= 1 ? this.hideUpload = true : this.hideUpload = false;
            this.uploadImg = '';
            this.minNewHistoryQuery.serieslogo = '';
        },
        logoBeforeUpload(file) {
            this.file = file;
            const isLt2M = file.size / 1024 / 1024 < 100;
            if (!isLt2M) {
                this.$message.error('The size of the uploaded picture cannot exceed 100MB!');
            }
            return isLt2M;
        },
        logoHandleSuccess1(response) {
            this.uploadImg1 = response.result.awsUrl;
            this.minNewHistoryQuery.seriesbanner = response.result.hash;
        },
        handleChange1(file, fileList1) {
            fileList1.length >= 1 ? this.hideUpload1 = true : this.hideUpload1 = false;
        },
        handleRemove1(file, fileList1) {
            fileList1.length >= 1 ? this.hideUpload1 = true : this.hideUpload1 = false;
            this.uploadImg1 = '';
            this.minNewHistoryQuery.seriesbanner = '';
        },
        logoHandleSuccess2(response) {
            this.uploadImg2 = response.result.awsUrl;
            this.minNewHistoryQuery.blindboxcover = response.result.hash;
        },
        handleChange2(file, fileList2) {
            fileList2.length >= 1 ? this.hideUpload2 = true : this.hideUpload2 = false;
        },
        handleRemove2(file, fileList2) {
            fileList2.length >= 1 ? this.hideUpload2 = true : this.hideUpload2 = false;
            this.uploadImg2 = '';
            this.minNewHistoryQuery.blindboxcover = '';
        },
        mintHandle() {
            if (this.minNewHistoryQuery.description !== '' && this.minNewHistoryQuery.seriesname !== '' && this.fileList !== '' && this.fileList1 !== '' && this.fileList2 !== '') {
                this.uploadtextRequire()
            }
        },
        uploadtextRequire() {
            if (this.mapState.chainId) {
                //obtain wallet address
                wallet.getWalletInfo().then((info) => {
                    this.minNewHistoryQuery.admin = info.address.toLowerCase();
                    this.minNewHistoryQuery.network = info.network;
                    this.mintServer();
                })
            }
        },
        mintServer() {
            let params = {
                admin: this.minNewHistoryQuery.admin,
                seriesid: this.$route.query.id,
                seriesname: this.minNewHistoryQuery.seriesname,
                serieslogo: this.minNewHistoryQuery.serieslogo,
                seriesbanner: this.minNewHistoryQuery.seriesbanner,
                description: this.minNewHistoryQuery.description,
                blindboxcover: this.minNewHistoryQuery.blindboxcover,
                network: this.minNewHistoryQuery.network,
                tokenaddress: this.$route.query.tokenAddress
            }
            this.$axios.post(this.baseUrlData + this.$api.post_updateserires, params).then(() => {
                setTimeout(() => {
                    this.$router.go(-1)
                }, 1000)
                this.$message({
                    message: 'Submitted successfully',
                    type: 'success'
                });
            })
        },
        querySeries() {
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let admin = info.address.toLowerCase();
                    let network = info.network;
                    let params = {
                        admin: admin,
                        seriesid: this.$route.query.id,
                        network: network
                    }
                    this.$axios.get(this.baseUrlData + this.$api.post_getseries, params).then((res) => {
                        let result = res.result
                        let obj = new Object();
                        obj.url = `https://cloudflare-ipfs.com/ipfs/${result.serieslogo}`;
                        this.hideUpload = true
                        this.fileList.push(obj);

                        let obj1 = new Object();
                        obj1.url = `https://cloudflare-ipfs.com/ipfs/${result.seriesbanner}`;
                        this.hideUpload1 = true
                        this.fileList1.push(obj1);

                        let obj2 = new Object();
                        obj2.url = `https://cloudflare-ipfs.com/ipfs/${result.blindboxcover}`;
                        this.hideUpload2 = true
                        this.fileList2.push(obj2);
                        this.minNewHistoryQuery = {
                            seriesname: result.seriesname,
                            description: result.description,
                            serieslogo: result.serieslogo,
                            seriesbanner: result.seriesbanner,
                            blindboxcover: result.blindboxcover,
                        }
                    })
                })
            }
        }
    },
    created() {
        this.baseUrlData = localStorage.getItem('baseUrl')
        if (this.$route.query.id) {
            this.querySeries()
        }
    }
}
</script>
<style lang="scss" src="../../assets/css/mintPage.scss" scoped></style>
<style lang="scss" scoped>
@media (max-width: 1125px) {
    #mint {
        margin: 3.68641rem 0 0;
        padding: 1.3312rem .768rem;

        .upLoadTitle {
            /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 500;
            font-size: .8192rem;
            line-height: 1.2288rem;
            /* or 150% */
            color: #353840;
            margin-bottom: .8192rem;
        }

        .uploadNotes {
            font-style: normal;
            font-weight: 500;
            font-size: .8192rem;
            line-height: 1.2288rem;
            /* or 150% */
            color: #353840;
            margin: 0 0 .6144rem 0;

        }

        .uploadNotesInfo {
            margin: 0;
            /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 400;
            font-size: .7168rem;
            line-height: 1.2288rem;
            /* or 171% */
            color: #707A83;

            &>span {
                display: block;
            }
        }

        .uploadImg {
            margin: .6144rem 0 0;
            width: 17.66405rem;
            height: 12.80003rem;
        }

        ::v-deep.form_view {
            margin-top: .8192rem;

            .form_view_title {
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 500;
                font-size: .8192rem;
                line-height: 1.2288rem;
                /* identical to box height, or 150% */
                color: #353840;
            }

            .form_view_input {
                margin-top: .6144rem;

                input {
                    width: 17.66405rem;
                    height: 2.56001rem;
                    border-radius: .512rem;
                    padding: 0 .6144rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: .7168rem;
                    line-height: 1.0752rem;
                    /* identical to box height */
                    color: #8A939B;

                }

                textarea {
                    width: 17.66405rem;
                    height: 9.72802rem;
                    border-radius: .512rem;
                    padding: .768rem .6144rem;
                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 400;
                    font-size: .7168rem;
                    line-height: 1.0752rem;
                    /* identical to box height */
                    color: #8A939B;

                }
            }

            .uploadNotesInfo {
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 400;
                font-size: .7168rem;
                line-height: 1.3312rem;
                /* or 186% */
                color: #707A83;
                margin-top: .6144rem;

                br {
                    display: none;
                }

            }

            .uploadImgTwo {
                margin-top: .6144rem;

                .el-upload--picture-card {
                    width: 7.68002rem;
                    height: 7.68002rem;
                }
            }





            .uploadImgThree {
                margin-top: .6144rem;

                .el-upload--picture-card {
                    width: 17.66405rem;
                    height: 12.80003rem;
                    border-radius: .8192rem;
                    border: .1024rem dashed #CCCCCC;

                    background: url(../../assets/imgs/uploadmint/1.svg) no-repeat center center;
                    background-size: 2.86721rem 2.86721rem;
                }
            }

            .uploadNotesInfo2 {
                margin-top: .6144rem;
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 400;
                font-size: .7168rem;
                line-height: 1.3312rem;
                /* or 186% */
                color: #707A83;

                .div1 {
                    // width: 247px;
                }

            }

            .switch-btn {
                margin-right: 0;
            }


        }

        .mintHandle_btn {
            margin-top: .6144rem;
            width: 100%;
            height: 2.56001rem;

            button {
                width: 17.66405rem;
                height: 2.56001rem;
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 500;
                font-size: .7168rem;
                line-height: 1.0752rem;
                /* identical to box height */
                text-align: center;


            }

            .loading_ani {}
        }
    }

    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
}
</style>